

function Home() {
  return (
<>
 
  {/* Magic Cursor Start */}
  <div id="magic-cursor">
    <div id="ball" />
  </div>
  {/* Magic Cursor End */}
  {/* Header Start */}
  <header className="main-header">
    <div className="header-sticky">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          {/* Logo Start */}
          <a className="navbar-brand" href="index.html">
            <img src="images/logo.png" alt="Logo" />
          </a>
          {/* Logo End */}
          {/* Main Menu start */}
          <div className="collapse navbar-collapse main-menu">
            <ul className="navbar-nav mr-auto" id="menu">
              {/* <>
              <li className="nav-item submenu">
                <a className="nav-link" >
                  Home
                </a>
                
              </li>
              <li className="nav-item">
                <a className="nav-link" >
                  About us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" >
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" >
                  Contact
                </a>
              </li>
              </> */}
              <li className="nav-item highlighted-menu">
                <a className="nav-link" href="https://crypto4u.org/auth/public/login">
                  Sign in
                </a>
              </li>
              <li className="nav-item highlighted-menu">
                <a className="nav-link" href="https://crypto4u.org/auth/public/login">
                  Log in
                </a>
              </li>
            </ul>
          </div>
          {/* Main Menu End */}
          <div className="navbar-toggle" />
        </div>
      </nav>
      <div className="responsive-menu" />
    </div>
  </header>
  {/* Header End */}
  {/* Hero Section Start */}
  <div className="hero parallaxie">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12">
          {/* Hero Left Content Start */}
          <div className="hero-content">
            <div className="section-title">
              <h3 className="wow fadeInUp">Welcome to Crypto4u</h3>
              <h1 className="text-anime">
                Easy Way to <span>Crypto4u</span> Key To Success.
              </h1>
            </div>
            <div
              className="hero-content-body wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <p>
              Unlock the secrets to crypto success with Crypto4u. Our comprehensive guide provides you with the essential tools and insights needed to navigate the complex world of cryptocurrencies. Whether you're a beginner looking to make your first investment or an experienced trader aiming to refine your strategies, Crypto4u offers expert advice, up-to-date market analysis, and proven techniques to maximize your profits. Start your journey with us and take the first step towards financial freedom and security in the digital age.

              </p>
            </div>
            <div
              className="hero-content-footer wow fadeInUp"
              data-wow-delay="0.75s"
            >
              <a href="https://crypto4u.org/auth/public/login" className="btn-default">
                Join for Free
              </a>
            </div>
            <div
              className="hero-content-footer wow fadeInUp"
              data-wow-delay="0.75s"
            >
              <a href="https://crypto4u.org/auth/public/login" className="btn-default">
                Login
              </a>
            </div>
          </div>
          {/* Hero Left Content End */}
        </div>
      </div>
    </div>
  </div>
  {/* Hero Section End */}
  {/* Coin Ticker Start */}
  <div className="coin-ticker">
    <div className="container-fluid">
      <div className="row no-gap">
        <div className="col-md-12">
          {/* Coin Ticker Box Start */}
          <div className="coin-ticker-box">
            <div className="scrolling-ticker-box">
              <div className="scrolling-content">
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-1.svg" alt="" />
                  </div>
                  <p>Crypto4u</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-2.svg" alt="" />
                  </div>
                  <p>Etherium</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-3.svg" alt="" />
                  </div>
                  <p>Tether</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-4.svg" alt="" />
                  </div>
                  <p>BNB</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-5.svg" alt="" />
                  </div>
                  <p>Solana</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-6.svg" alt="" />
                  </div>
                  <p>USD Coin</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-7.svg" alt="" />
                  </div>
                  <p>Cardano</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-8.svg" alt="" />
                  </div>
                  <p>Cardano</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-9.svg" alt="" />
                  </div>
                  <p>Dogecoin</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-10.svg" alt="" />
                  </div>
                  <p>Tron</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-11.svg" alt="" />
                  </div>
                  <p>Polygon</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-12.svg" alt="" />
                  </div>
                  <p>Shiba INU</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-13.svg" alt="" />
                  </div>
                  <p>Lite Coin</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-14.svg" alt="" />
                  </div>
                  <p>Stacks</p>
                </div>
              </div>
              <div className="scrolling-content">
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-1.svg" alt="" />
                  </div>
                  <p>Crypto4u</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-2.svg" alt="" />
                  </div>
                  <p>Etherium</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-3.svg" alt="" />
                  </div>
                  <p>Tether</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-4.svg" alt="" />
                  </div>
                  <p>BNB</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-5.svg" alt="" />
                  </div>
                  <p>Solana</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-6.svg" alt="" />
                  </div>
                  <p>USD Coin</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-7.svg" alt="" />
                  </div>
                  <p>Cardano</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-8.svg" alt="" />
                  </div>
                  <p>Cardano</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-9.svg" alt="" />
                  </div>
                  <p>Dogecoin</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-10.svg" alt="" />
                  </div>
                  <p>Tron</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-11.svg" alt="" />
                  </div>
                  <p>Polygon</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-12.svg" alt="" />
                  </div>
                  <p>Shiba INU</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-13.svg" alt="" />
                  </div>
                  <p>Lite Coin</p>
                </div>
                <div className="scrolling-item">
                  <div className="icon-box">
                    <img src="images/icon-ticker-14.svg" alt="" />
                  </div>
                  <p>Stacks</p>
                </div>
              </div>
            </div>
          </div>
          {/* Coin Ticker Box End */}
        </div>
      </div>
    </div>
  </div>
  {/* Coin Ticker End */}
  {/* About us Section Start */}
  <div className="about-us">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {/* Section Title Start */}
          <div className="section-title">
            <h3 className="wow fadeInUp">About Crypto4u</h3>
            <h2 className="text-anime">Simple. Faster. Secure</h2>
          </div>
          {/* Section Title End */}
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-6 col-12">
          {/* About Images Start */}
          <div className="about-images">
            <div className="about-image">
              <figure className="image-anime reveal">
                <img src="images/about-us-1.jpg" alt="" />
              </figure>
            </div>
            <div className="about-image">
              <figure className="image-anime reveal">
                <img src="images/about-us-2.jpg" alt="" />
              </figure>
              <div
                className="about-info-item wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="icon-box">
                  <img src="images/icon-Crypto4u-exchange.svg" alt="" />
                </div>
                <h5>Crypto4u Exchange</h5>
              </div>
            </div>
          </div>
          {/* About Images End */}
        </div>
        <div className="col-lg-6 col-12">
          {/* About Content Start */}
          <div className="about-content">
            <div className="about-body wow fadeInUp" data-wow-delay="0.25s">
              <p>
              Experience the future of transactions with our platform: Simple, Faster, Secure. Designed for ease of use, our intuitive interface ensures you can manage your finances effortlessly. Enjoy lightning-fast transaction speeds that keep you ahead in the dynamic digital landscape. 
              </p>
              <p>
              With top-tier security measures, your assets are protected at all times, providing you with peace of mind. Join us and revolutionize the way you handle your finances, making it simpler, faster, and more secure than ever before.

              </p>
            </div>
            <div className="about-list-item wow fadeInUp" data-wow-delay="0.5s">
              <ul>
                <li>Designed for everyone</li>
                <li>Trade as you go</li>
                <li>All the tools you want</li>
                <li>Automatic conversion</li>
                <li>Multiple asset classes</li>
                <li>Simple to manage</li>
                <li>Scan. Convert. Pay.</li>
                <li>Quick to set up</li>
              </ul>
            </div>
            <div className="about-footer wow fadeInUp" data-wow-delay="0.75s">
              <a href="#" className="btn-default">
                Read More
              </a>
            </div>
          </div>
          {/* About Content End */}
        </div>
      </div>
    </div>
  </div>
  {/* About us Section End */}
  {/* How It Works Section Start */}
  <div className="how-it-works">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {/* Section Title Start */}
          <div className="section-title">
            <h3 className="wow fadeInUp">How it Works</h3>
            <h2 className="text-anime">Get Started Today with Crypto4u</h2>
          </div>
          {/* Section Title End */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          {/* How It Work Item Start */}
          <div
            className="how-it-works-item wow fadeInUp"
            data-wow-delay="0.25s"
          >
            <div className="icon-box">
              <img src="images/icon-how-it-work-1.svg" alt="" />
            </div>
            <h3>Download Crypto4u Wallet</h3>
            <p>Get it on PC or Mobile to create, send and receive Crypto4us.</p>
          </div>
          {/* How It Work Item End */}
        </div>
        <div className="col-md-4">
          {/* How It Work Item Start */}
          <div className="how-it-works-item wow fadeInUp" data-wow-delay="0.5s">
            <div className="icon-box">
              <img src="images/icon-how-it-work-2.svg" alt="" />
            </div>
            <h3>Add Coins to Your Wallet</h3>
            <p>Add Crypto4us you’ve created or exchanged via credit card.</p>
          </div>
          {/* How It Work Item End */}
        </div>
        <div className="col-md-4">
          {/* How It Work Item Start */}
          <div
            className="how-it-works-item wow fadeInUp"
            data-wow-delay="0.75s"
          >
            <div className="icon-box">
              <img src="images/icon-how-it-work-3.svg" alt="" />
            </div>
            <h3>Buy/Sell with Wallet</h3>
            <p>Enter receiver's address, specify the amount and send.</p>
          </div>
          {/* How It Work Item End */}
        </div>
      </div>
    </div>
  </div>
  {/* How It Works Section End */}

  {/* Our Services Section Start */}
  <div className="our-services">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {/* Section Title Start */}
          <div className="section-title">
            <h3 className="wow fadeInUp">Our Services</h3>
            <h2 className="text-anime">Explore Crypto4u Services</h2>
          </div>
          {/* Section Title End */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          {/* Service Item Start */}
          <div className="service-item wow fadeInUp" data-wow-delay="0.25s">
            <div className="icon-box">
              <img src="images/icon-our-services-1.svg" alt="" />
            </div>
            <h3>Smart Trading Modules</h3>
            <p>
            Optimize your trading with Smart Trading Modules. Automate strategies, gain insights, and maximize profits effortlessly.
            </p>
          </div>
          {/* Service Item End */}
        </div>
        <div className="col-lg-4 col-md-6">
          {/* Service Item Start */}
          <div
            className="service-item active wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="icon-box">
              <img src="images/icon-our-services-2.svg" alt="" />
            </div>
            <h3>Adaptive Social Assistant</h3>
            <p>
            Enhance your social interactions with Adaptive Social Assistant. Tailored insights and smart features to improve engagement and connections effortlessly.
            </p>
          </div>
          {/* Service Item End */}
        </div>
        <div className="col-lg-4 col-md-6">
          {/* Service Item Start */}
          <div className="service-item wow fadeInUp" data-wow-delay="0.75s">
            <div className="icon-box">
              <img src="images/icon-our-services-3.svg" alt="" />
            </div>
            <h3>Analyzer of the News with powerful AII</h3>
            <p>
            Stay informed with Analyzer of the News. Leverage powerful AI for accurate, real-time insights and analysis.
            </p>
          </div>
          {/* Service Item End */}
        </div>
        <div className="col-lg-4 col-md-6">
          {/* Service Item Start */}
          <div className="service-item wow fadeInUp" data-wow-delay="1.0s">
            <div className="icon-box">
              <img src="images/icon-our-services-4.svg" alt="" />
            </div>
            <h3>Exchange Order Management</h3>
            <p>
            Streamline your trading with Exchange Order Management. Efficiently manage orders and optimize your trading performance.
            </p>
          </div>
          {/* Service Item End */}
        </div>
        <div className="col-lg-4 col-md-6">
          {/* Service Item Start */}
          <div className="service-item wow fadeInUp" data-wow-delay="1.25s">
            <div className="icon-box">
              <img src="images/icon-our-services-5.svg" alt="" />
            </div>
            <h3>Module of Price Notification</h3>
            <p>
            Stay updated with the Module of Price Notification. Receive real-time alerts and never miss important price changes.
            </p>
          </div>
          {/* Service Item End */}
        </div>
        <div className="col-lg-4 col-md-6">
          {/* Service Item Start */}
          <div className="service-item wow fadeInUp" data-wow-delay="1.50s">
            <div className="icon-box">
              <img src="images/icon-our-services-6.svg" alt="" />
            </div>
            <h3>Crypto Trading Platform</h3>
            <p>
            Access the Crypto Trading Platform for seamless transactions and robust security features.
            </p>
          </div>
          {/* Service Item End */}
        </div>
      </div>
    </div>
  </div>
  {/* Our Services Section End */}
  {/* Price Section Start */}
  <div className="price-section">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          {/* Section Title Start */}
          <div className="section-title">
            <h3 className="wow fadeInUp">Price</h3>
            <h2 className="text-anime">Explore Cryptocurrency Price</h2>
          </div>
          {/* Section Title End */}
        </div>
      </div>
      <div className="row no-gap">
        <div className="col-md-12">
          {/* Price Carousel Start */}
          <div className="price-carousel price-carousel-left ">
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-1.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Bitcoin</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-2.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Etherium</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-3.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Tether</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-4.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>BNB</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-5.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Solana</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-6.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>USD Coin</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-7.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Cardano</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-8.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Cardano</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-9.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Dogecoin</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-10.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Tron</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-11.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Polygon</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-12.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Shiba INU</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
              </div>
            </div>
          </div>
          {/* Price Carousel End */}
          {/* Price Carousel Start */}
          <div className="price-carousel price-carousel-right">
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-13.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Lite Coin</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-14.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Stacks</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-15.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Toncoin</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-16.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Filecoin</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-17.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Hedera</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-18.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>DigiByte</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-19.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Centrifuge</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-20.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Flux</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-21.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Compound</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-22.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Frax Share</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-23.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Gemini Dollar</h4>
                      <p>
                        $12.185<span className="price-green">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
                <div className="swiper-slide">
                  {/* price Item Start */}
                  <div className="price-item">
                    <div className="icon-box">
                      <img src="images/icon-ticker-24.svg" alt="" />
                    </div>
                    <div className="price-body">
                      <h4>Maker</h4>
                      <p>
                        $12.185<span className="price-red">21.30%</span>
                      </p>
                      <a href="#" className="btn-default">
                        Order Now
                      </a>
                    </div>
                  </div>
                  {/* Price Item End */}
                </div>
              </div>
            </div>
          </div>
          {/* Price Carousel End */}
        </div>
      </div>
    </div>
  </div>
  {/* Price Section End */}
  {/* Intro Video Section Start */}
  <div className="intro-video">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {/* Section Title Start */}
          <div className="section-title">
            <h3 className="wow fadeInUp">Our Dashboard</h3>
            <h2 className="text-anime">Watch Our Demo Video</h2>
          </div>
          {/* Section Title End */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* Intro Video Box Start */}
          <div className="intro-video-box">
            <div className="intro-video-image">
              <img src="images/video-bg.png" alt="" />
            </div>
            <div className="video-play-button">
              <a
                href="https://www.youtube.com/watch?v=Y-x0efG1seA"
                className="popup-video"
              >
                <img src="images/play.svg" alt="" />
              </a>
            </div>
          </div>
          {/* Intro Video Box End */}
        </div>
      </div>
    </div>
  </div>
  {/* Intro Video Section End */}
  {/* Why Choose us Section Start */}
  <div className="why-choose-us">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {/* Section Title Start */}
          <div className="section-title">
            <h3 className="wow fadeInUp">Why Choose us ?</h3>
            <h2 className="text-anime">Know More About Crypto4u</h2>
          </div>
          {/* Section Title End */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          {/* Why Choose us Item Start */}
          <div
            className="why-choose-us-item wow fadeInUp"
            data-wow-delay="0.25s"
          >
            <div className="icon-box">
              <img src="images/icon-why-choose-us-1.svg" alt="" />
            </div>
            <h3>Safe &amp; Secure</h3>
            <p>
            Experience Safe & Secure transactions with our advanced encryption and trusted protocols.
            </p>
          </div>
          {/* Why Choose us Item End */}
        </div>
        <div className="col-md-4">
          {/* Why Choose us Item Start */}
          <div
            className="why-choose-us-item wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="icon-box">
              <img src="images/icon-why-choose-us-2.svg" alt="" />
            </div>
            <h3>Early Bonus</h3>
            <p>
            Receive an Early Bonus with exclusive perks and rewards for early participation.
            </p>
          </div>
          {/* Why Choose us Item End */}
        </div>
        <div className="col-md-4">
          {/* Why Choose us Item Start */}
          <div
            className="why-choose-us-item wow fadeInUp"
            data-wow-delay="0.75s"
          >
            <div className="icon-box">
              <img src="images/icon-why-choose-us-3.svg" alt="" />
            </div>
            <h3>Several Profit</h3>
            <p>
            Achieve Several Profits with diversified strategies and smart investment decisions.
            </p>
          </div>
          {/* Why Choose us Item End */}
        </div>
      </div>
    </div>
  </div>
  {/* Why Choose us Section End */}
  {/* Download Apps Section Start */}
  <div className="download-apps">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12">
          {/* Download App Content Start */}
          <div className="download-apps-content">
            {/* Section Title Start */}
            <div className="section-title">
              <h3 className="wow fadeInUp">Download App</h3>
              <h2 className="text-anime">Download Crypto4u App</h2>
            </div>
            {/* Section Title End */}
            {/* Download App Body Start */}
            <div
              className="download-app-body wow fadeInUp"
              data-wow-delay="0.25s"
            >
              <p>
              Download the Crypto4u App for convenient access to crypto insights and trading tools on the go.
              </p>
            </div>
            {/* Download App Body End */}
          </div>
          {/* Download App Content End */}
        </div>
        <div className="col-lg-3 col-md-6">
          {/* Download App Item Start */}
          <div className="download-app-item wow fadeInUp" data-wow-delay="0.5s">
            <h3>Get App for IOS</h3>
            <a href="#" className="btn-default">
              <i className="fa-brands fa-app-store" /> Download Now
            </a>
            <div className="download-app-img">
              <img src="images/mobile-img.png" alt="" />
            </div>
          </div>
          {/* Download App Item End */}
        </div>
        <div className="col-lg-3 col-md-6">
          {/* Download App Item Start */}
          <div
            className="download-app-item wow fadeInUp"
            data-wow-delay="0.75s"
          >
            <h3>Get App for IOS</h3>
            <a href="#" className="btn-default">
              <i className="fa-brands fa-google-play" /> Download Now
            </a>
            <div className="download-app-img">
              <img src="images/mobile-img.png" alt="" />
            </div>
          </div>
          {/* Download App Item End */}
        </div>
      </div>
    </div>
  </div>
  {/* Download Apps Section End */}
  {/* Crypto Calculator Section Start */}
  <div className="crypto-calculator">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {/* Crypto Calculator Box Start */}
          <div className="crypto-calculator-box">
            <div className="row">
              <div className="col-md-12">
                {/* Section Title Start */}
                <div className="section-title">
                  <h3 className="wow fadeInUp">Crypto4u Calculator</h3>
                  <h2 className="text-anime">Buy Cryptocurrency</h2>
                </div>
                {/* Section Title End */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="crypto-form-box">
                  <form
                    id="CryptoCalculator"
                    action="#"
                    method="POST"
                    data-toggle="validator"
                  >
                    <div className="row no-gap">
                      <div className="form-group col-lg-6 col-12 mb-4">
                        <select
                          id="cryptocurrency_dropdown"
                          name="cryptocurrency"
                        >
                          <option value="btc">₿ BTC</option>
                          <option value="eth">€ ETH</option>
                          <option value="bnb">$ BNB</option>
                          <option value="sol">¥ SOL</option>
                        </select>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="cryptocurrency_price"
                          placeholder="Crypto Currencies"
                        />
                      </div>
                      <div className="form-group col-lg-6 col-12 mb-4">
                        <select id="moneycurrency_dropdown" name="cars">
                          <option value="inr">₹ INR</option>
                          <option value="euro">€ Euro</option>
                          <option value="usd">$ USD</option>
                          <option value="cny">¥ CNY</option>
                        </select>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="moneycurrency_price"
                          placeholder="Price"
                        />
                      </div>
                      <div className="col-md-12 text-center">
                        <button type="submit" className="btn-default">
                          Calculate Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Crypto Calculator Box End */}
        </div>
      </div>
    </div>
  </div>
  {/* Crypto Calculator Section End */}

 


  {/* Footer Start */}
  <footer className="main-footer">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {/* Footer Newsletters Start */}
          <div className="footer-newsletters">
            <div className="row">
              <div className="col-lg-6">
                {/* Newsletter Title Start */}
                <div className="newsletter-title">
                  <div className="icon-box">
                    <img src="images/icon-stay-info.svg" alt="" />
                  </div>
                  <h2>Stay Informed And Never Miss An Crypto4u Update!</h2>
                </div>
                {/* Newsletter Title End */}
              </div>
              <div className="col-lg-6">
                {/* Newsletter Form Start */}
                <div className="newsletter-form">
                  <form id="newsletter_form" action="#" data-toggle="validator">
                    <div className="row no-gap align-items-center">
                      <div className="form-group col-md-8">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="news_email"
                          placeholder="Enter Your Email Address"
                          required=""
                        />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="col-md-4 text-end">
                        <button type="submit" className="btn-default disabled">
                          Subscribe Now
                        </button>
                        <div
                          id="news_letter_Submit"
                          className="h3 text-left hidden"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                {/* Newsletter Form End */}
              </div>
            </div>
          </div>
          {/* Footer Newsletters End */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* Mega Footer Start */}
          <div className="mega-footer">
            <div className="row">
              <div className="col-lg-3 col-12">
                {/* Footer About Start */}
                <div className="footer-about">
                  {/* Footer Logo Start */}
                  <div className="footer-logo">
                    <img src="images/logo.png" alt="" />
                  </div>
                  {/* Footer Logo End */}
                  {/* Footer About Content Start */}
                  <div className="footer-about-content">
                    <p>
                    Follow us on all social media platforms.
                    </p>
                  </div>
                  {/* Footer About Content End */}
                  {/* Footer Social Links Start */}
                  <div className="footer-social-links">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Footer Social Links End */}
                </div>
                {/* Footer About End */}
              </div>
              <div className="col-lg-3 col-md-4">
                {/* Footer Links Start */}
                <div className="footer-links">
                  {/* Footer Title Start */}
                  <div className="footer-title">
                    <h3>Quick Links</h3>
                  </div>
                  {/* Footer Title End */}
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="#">Home</a>
                      </li>
                      <li>
                        <a href="#">About Us</a>
                      </li>
                      <li>
                        <a href="#">Services</a>
                      </li>
                      <li>
                        <a href="#">Pricing</a>
                      </li>
                      <li>
                        <a href="#">Blog</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Footer Links End */}
              </div>
              <div className="col-lg-3 col-md-4">
                {/* Footer Links Start */}
                <div className="footer-links">
                  {/* Footer Title Start  */}
                  <div className="footer-title">
                    <h3>Extra Links</h3>
                  </div>
                  {/* Footer Title End */}
                  <div className="footer-menu">
                    <ul>
                     
                      <li>
                        <a href="#">Features</a>
                      </li>
                      <li>
                        <a href="https://crypto4u.org/auth/public/login">Join Us</a>
                      </li>
                     
                      <li>
                        <a href="#">Faqs</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Footer Links End */}
              </div>
              <div className="col-lg-3 col-md-4">
                {/* Footer Contact information Start */}
                <div className="footer-contact-information">
                  {/* Footer Title Start */}
                  <div className="footer-title">
                    <h3>Contact Information</h3>
                  </div>
                  {/* Footer Title End */}
                  <div className="footer-contact-info">
                    {/* Footer Contact info Item Start */}
                    <div className="footer-contact-info-item">
                      <div className="icon-box">
                        <i className="fa-solid fa-phone" />
                      </div>
                      <p>(+0) 123 456 7890</p>
                    </div>
                    {/* Footer Contact info Item End */}
                    {/* Footer Contact info Item Start */}
                    <div className="footer-contact-info-item">
                      <div className="icon-box">
                        <i className="fa-solid fa-envelope" />
                      </div>
                      <p>Info@Crypto4u.com</p>
                    </div>
                    {/* Footer Contact info Item End */}
                    {/* Footer Contact info Item Start */}
                    <div className="footer-contact-info-item">
                      <div className="icon-box">
                        <i className="fa-solid fa-location-dot" />
                      </div>
                      <p>200 East 65th Street 17 No, Australia</p>
                    </div>
                    {/* Footer Contact info Item End */}
                  </div>
                </div>
                {/* Footer Contact information End */}
              </div>
            </div>
          </div>
          {/* Mega Footer End */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* Copyright Footer Start */}
          <div className="footer-copyrights">
            <div className="row align-items-center">
              <div className="col-md-6">
                {/* Copyright Content Start */}
                <div className="footer-copyright">
                  <p>Copyright © 2024 Crypto4u. All Rights Reserved.</p>
                </div>
                {/* Copyright Content End */}
              </div>
              <div className="col-md-6">
                {/* Footer Policy Menu Start */}
                <div className="footer-policy-menu">
                  <ul>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Terms of Use</a>
                    </li>
                  </ul>
                </div>
                {/* Footer Policy Menu End */}
              </div>
            </div>
          </div>
          {/* Copyright Footer End */}
        </div>
      </div>
    </div>
  </footer>
  {/* Footer End */}
  {/* Jquery Library File */}
  {/* Bootstrap js file */}
  {/* Validator js file */}
  {/* SlickNav js file */}
  {/* Swiper js file */}
  {/* Counter js file */}
  {/* Magnific js file */}
  {/* SmoothScroll */}
  {/* Parallax js */}
  {/* MagicCursor js file */}
  {/* Text Effect js file */}
  {/* YTPlayer js file */}
  {/* Wow js file */}
  {/* Main Custom js file */}
</>

  );
}

export default Home;
